import ArticleList from "../../../components/articleList/ArticleList";
import NeedHelp from "../../../components/needHelp/NeedHelp";
import Footer from "../../../layout/footer";
import Header from "../../../layout/header";
import CustomersSay from "../../../components/customersSay/CustomersSay";
import ArticlesServices from "../../../components/articlesServices/ArticlesServices";
import Publish from "../../../components/publish/Publish";
import Video from "../../../components/video/Video";
import Article from "../../../components/article/Article";
import "./index.scss";

function Dashboard() {

    return <>
        <div>
            <Header />
            <div className="container m-auto">
                <Article />
            </div>

            <Publish />

            <div className="container m-auto">
                <Video />
            </div>

            <div className="container m-auto">
                <CustomersSay />
            </div>

            <div className="container m-auto">
                <ArticleList />
            </div>

            <div className="container m-auto">
                <ArticlesServices />
            </div>
            <div>
                <NeedHelp />
            </div>
            <Footer />
        </div >
    </>
}
export default Dashboard