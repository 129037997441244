import Header from "../Homepage/header";
import { Link } from "react-router-dom";
import Footer from "../Homepage/Footer";
import arrowLeft from "../../assets/img/icons/arrow_left.svg";
import arrowRight from "../../assets/img/icons/arrow_right.svg";
import person1 from "../../assets/img/members/person_12.jpg";
import sample5 from "../../assets/img/sample/sample_05.jpg";
import sample4 from "../../assets/img/sample/sample_04.jpg";
import { useNavigate } from "react-router-dom";

const MemberDetails = () => {
    const navigate = useNavigate();
    const handleRedirect = (e, path = "/registration") => {
        e.preventDefault();
        window.scroll({top: 0, behavior: 'smooth'});
        navigate(path)
    }

    const handleChatBot = (e) => {
        e.preventDefault();
        window.zE('messenger', 'open');
    }

    return(
        <>
            <Header />
            <div className="academicSection innerPages">
                <div className="containWrap">
                    <a onClick={(e) => handleRedirect(e,'/advisory')} className="memberBack">
                    Back
                    <b>
                        <img src={arrowLeft} alt="#" />
                    </b>
                    </a>
                </div>

                <div className="memberBar">
                    <div className="containWrap">
                    <div className="memberBio">
                        <div className="memberBio-img">
                        {" "}
                        <img src={person1} alt="#" />
                        </div>
                        <div className="memberBio-intro">
                            <h1>Dr. Asha Bhandarker </h1>
                            <p>
                                Distinguished Professor, Org Behavior, IMI-Delhi<br/>
                                Independent Director, Punjab National Bank<br />
                                Dean Research and Consulting at MDI-Gurgaon
                            </p>
                        </div>
                    </div>
                    </div>
                </div>

                <div>
                    <div className="containWrap">
                    <div className="memberRow">
                        <div className="memberLeft">
                        <div className="memberBio-sum">
                            <p>
                                Dr. Bhandarker is well known in the field of Leadership and HR as a scholar, consultant, and researcher. She is a psychologist by training, a management professor, coach and counsellor by passion and a strong voice for diversity at the workplace.
                            </p>

                            <p>
                                She has published nine books and fourty five research papers and cases in peer reviewed national and international journals. Many of her publications have been co-authored with <br /><Link to="/member-details/late-pritam-singh">Padma Shri Dr.Pritam Singh</Link>.
                            </p>
                            <p>
                                Dr. Bhandarker is the recipient of several accolades and awards for her work including the Senior Fulbright Fellowship for Research, Leadership Thinker Award, Best Teacher Award and the Woman of Excellence Award.
                            </p>
                            <p>
                                She has served as an Independent Directoron the board of Punjab National Bank and was member of many board level bank HR committees in Bank of Baroda, Corporation Bank, Oriental Bank of Commerce, Punjab National Bank and Dena Bank.
                            </p>
                            <p>
                                She has worked closely with the corporate sector for decades. These include companies in the Public Sector, Cooperative Sector as well as the Private sector. She has conducted many Board levels and Top management workshops on topics of Culture, Leadership Development and Organizational Vision Building in many companies across sector.
                            </p>
                            <p>
                                Dr. Bhandarker is widely travelled and has consulted in places like Afghanistan, Egypt, France, Mauritius, Sri Lanka, and USA.
                            </p>
                        </div>
                        <div className="member-back">
                  {/* <div className="containWrap"> */}
                    <a
                      onClick={(e) => handleRedirect(e, "/advisory")}
                      className="memberBack"
                    >
                      Back
                      <b>
                        <img src={arrowLeft} alt="#" />
                      </b>
                    </a>
                  {/* </div> */}
                  {/* <div className="containWrap"> */}
                    <a
                      onClick={(e) =>
                        handleRedirect(e, "/member-details/anmol-pandit")
                      }
                      className="memberBack"
                    >
                      Next
                      <b>
                        <img src={arrowRight} alt="#" />
                      </b>
                    </a>
                  {/* </div> */}
                </div>
                        </div>
                        <div className="memberRight">
                        <div className="checkOut">
                            <h2>Also Check Out</h2>
                            <div className="checkBox">
                            <div className="checkBox-img">
                                <img src={sample5} alt="#" />
                            </div>
                            <div className="checkBox-info">
                                <h3>Step into Greatness</h3>
                                <p>
                                Your words have the power to change the world. Register as a
                                Peer Reviewer and/or a Publishing Editor.
                                </p>
                            </div>
                            <Link to="/registration">REGISTER NOW</Link>
                            </div>
                            <div className="checkBox">
                            <div className="checkBox-img">
                                <img src={sample4} alt="#" />
                            </div>
                            <div className="checkBox-info">
                                <h3>Meet the Dream Catchers</h3>
                                <p>
                                Who are brave enough to dream of a different future for what is
                                today referred to as, the developing world.
                                </p>
                            </div>
                            <Link to="/teams">THE TEAM</Link>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div
                        className="pullUp"
                        onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
                    >
                        <b className="icon-arrow-right" />
                    </div>
                    <div class="row align-items-center justify-content-between contentFooter"><div class="col text-end"><a onClick={handleChatBot} class="helpLink"><b class="icon-help"></b>Help</a></div></div>
                </div>
            </div>

            <Footer />
        </>
    )
}


export default MemberDetails;