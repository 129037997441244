import Logo from "../../assets/img/logos/aakashganga_full_logo.png";

const HomeHeader = () => {
    return(
        <header className="fixed home-header">
            <div className="cmyLogo">
                <img src={Logo} alt="#" />
            </div>
            <div className="navheader">
                <div className="navLink">
                    <ul>
                    <li>
                        <a>Home</a>
                    </li>
                    <li>
                        <a>Articles</a>
                    </li>
                    <li>
                        <a>Seva Haat</a>
                    </li>
                    <li>
                        <a className="button button-primary">Submit Article</a>
                    </li>
                    </ul>
                </div>

                <div className="navNotify">
                    <a>
                        <b className="icon-bell" />
                        <span />
                    </a>
                </div>
                <div className="navDashboard">
                    <a href="ag_my_dashboard.html">
                        <b className="icon-dashboard" />
                        Aakashganga
                    </a>
                </div>
            </div>
        </header>
    )
}

export default HomeHeader;