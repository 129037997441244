import repueeLogo from "../../assets/img/icons/rupee.svg";
const Sidebar = () => {
    return(
        <div className="pageMenu text-initial">
            <div className="pageMenu-link">
            <ul>
                <li>
                <a className="active">My Profile</a>
                <div className="onlineStatus">
                    Online{" "}
                    <div className="form-check form-switch d-inline-flex mb-0 ms-2">
                    {" "}
                    <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked=""
                    />
                    </div>
                </div>
                </li>
                <li>
                <a>Mudra Kosh</a>
                <div className="walletStatus">
                    <b>
                    <img src={repueeLogo} alt="#" />
                    </b>
                    Balance: Rs.5000
                </div>
                </li>
            </ul>
            </div>
            <div className="pageMenu-link">
            <ul>
                <li>
                <div className="onlineStatus">My Articles</div>
                <a>Drafts</a>
                </li>
                <li>
                <a>Under Review</a>
                </li>
                <li>
                <a>Approved</a>
                </li>
                <li>
                <a>Rejected</a>
                </li>
                <li>
                <a>Published</a>
                </li>
            </ul>
            </div>
            <div className="pageMenu-link">
            <ul>
                <li>
                <a>My Services</a>
                </li>
                <li>
                <a>Reading List</a>
                </li>
                <li>
                <a>Resource Centre</a>
                </li>
                <li>
                <a>Blog</a>
                </li>
                <li>
                <a>Need Help?</a>
                </li>
            </ul>
            </div>
            <div className="pageMenu-link">
            <ul>
                <li>
                <a>
                    <b className="icon-logout" /> Logout
                </a>
                </li>
            </ul>
            </div>
        </div>

    )
}

export default Sidebar;