import Header from "../Homepage/header";
import Footer from "../Homepage/Footer";
import aabp from "../../assets/img/mediacoverage/aabp.jpg";
import vikramBeginning from "../../assets/img/mediacoverage/vikram-beginning.jpg";
import lowestApc from "../../assets/img/mediacoverage/lowest-apc.jpg";
import riYrBook from "../../assets/img/mediacoverage/ri-yr-book.jpeg";
import frontrunner from "../../assets/img/mediacoverage/frontrunner.jpeg";
import downloadimg from "../../assets/img/mediacoverage/download.jpeg";
import Aakashganga from "../../assets/img/mediacoverage/good-night.gif";


const MediaCoverage = () => {
    const handleChatBot = (e) => {
        e.preventDefault();
        window.zE('messenger', 'open'); 
    }
    
    return (
        <>
            <Header />
             <div className="resourceSection innerPages" style={{ background: 'none'}} >
                <div className="containWrap">
                    <div className="resourceWrap playListHead pl-0 pb-3">
                        <h1 className="media-cover-h1" style={{backgroundImage: `url('https://i.postimg.cc/rsjM0Hh6/bg.png')`}}>
                        Welcome to Our Media Coverage Hub!
                        </h1>
                        <p style={{ textAlign:'justify'}}>We're thrilled to share the buzz surrounding Vikramshila and Aakashganga Open. From glowing reviews to being featured as one of the impactful international voices of the industry, we are thrilled for being recognized for our commitment to innovation, knowledge democratization, and transforming the landscape of academic publishing.</p>

                        <p style={{ textAlign:'justify'}}>Let's continue to inspire, innovate, and shape the future of knowledge together.</p>
 

                        <p style={{ textAlign:'justify'}}>We are always looking for new opportunities to share our story with the public. If you are interested in writing about Vikramshila Education or Aakashganga Open, please write to us here: <a href="mailto:contactus@vikramshilaedu.in">contactus@vikramshilaedu.in</a></p>
                        
                    </div>
                    <div className="playListWrap">
                        <ul style={{ display: 'flex', gap: '20px', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                            <li className="playListBoxli">
                                <div className="playListBox">
                                    <span>
                                        <img src={Aakashganga} />
                                    </span>
                                    <div className="playList-text">
                                       <a style={{ fontSize: '20px' }} href="https://www.libcognizance.com/2024/03/akashganga-galaxy-of-opportunities-open.html">Aakashganga a galaxy of opportunities: open access portal on Indian scholarship</a>
                                    </div>
                                </div>
                            </li>
                            <li className="playListBoxli">
                                <div className="playListBox" >
                                    <span>
                                        <img src={lowestApc} />
                                    </span>
                                    <div  className="playList-text">
                                       <a style={{ fontSize: '20px' }} href="https://www.researchinformation.info/news/new-indian-platform-offers-worlds-lowest-apc">New Indian platform, Aakashganga Open offers 'World's Lowest APC'</a>
                                      
                                    </div>
                                </div>
                            </li>
                            <li className="playListBoxli">
                                <div className="playListBox">
                                    <span>
                                        <img src={riYrBook} />
                                    </span>
                                
                                    <div className="playList-text">
                                       <a style={{ fontSize: '20px' }} href="https://www.researchinformation.info/news/research-information-yearbook-2023-24-out-now">Launch of Vikramshila Education is one of the key development in Publishing in the world – Research Information</a>
                                      
                                    </div>
                                </div>
                            </li>
                            <li className="playListBoxli">
                                <div className="playListBox">
                                    <span>
                                        <img src={vikramBeginning} />
                                    </span>
                                    <div className="playList-text">
                                       <a style={{ fontSize: '20px' }} href="https://www.researchinformation.info/interview/born-india-world">Taking Publishing out of the West’s Hands. How Vikramshila is bringing India into focus</a>
                                      
                                    </div>
                                </div>
                            </li>
                            <li className="playListBoxli">
                                <div className="playListBox">
                                    <span>
                                        <img src={frontrunner} />
                                    </span>
                                    <div className="playList-text">
                                       <a style={{ fontSize: '20px' }} href="https://www.researchinformation.info/news/company-wants-see-india-industry-frontrunner">Company wants to see India as an industry 'frontrunner'</a>
                                      
                                    </div>
                                </div>
                            </li>
                            <li className="playListBoxli">
                                <div className="playListBox">
                                    <span>
                                        <img src={aabp} />
                                    </span>
                                    <div className="playList-text">
                                       <a style={{ fontSize: '20px' }} href="https://www.allaboutbookpublishing.com/10537/vikramshila-making-research-journey-more-transparent-efficient-and-affordable/">Vikramshila: Making Research Journey More Transparent, Efficient And Affordable</a>
                                      
                                    </div>
                                </div>
                            </li>
                            <li className="playListBoxli">
                                <div className="playListBox">
                                    <span>
                                        <img src={downloadimg} />
                                    </span>
                                    <div className="playList-text">
                                       <a style={{ fontSize: '20px' }} href="https://www.researchinformation.info/feature/platforms-note">What authors should be looking for when disseminating their work</a>
                                    </div>
                                </div>
                            </li>
                            
                             
                        </ul>
                    </div>
                    <div
                        className="pullUp"
                        onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
                    >
                        <b className="icon-arrow-right" />
                    </div>
                    <div class="row align-items-center justify-content-between contentFooter"><div class="col text-end"><a onClick={handleChatBot} class="helpLink"><b class="icon-help"></b>Help</a></div></div>
                </div>
            </div>

            <Footer />
        </>
    )
}


export default MediaCoverage;