import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../../components/common/Sidebar";
import Details from "./Details";
import PhoneUpdate from "./PhoneUpdate";
import PasswordUpdate from "./PasswordUpdate";
import jwt_decode from "jwt-decode";
import { getProfileDetails } from "../../../store/apiCalls/profileDetails";
import { editProfileData } from "../../../utils";
import EditProfile from "./Edit";
/*import "../../../assets/scss/base_app.scss";
import "../../../assets/scss/modules/_header_app.scss";
import "../../../assets/scss/modules/_menu_app.scss";
import "../../../assets/scss/sections/_profile_app.scss";*/

const initialModalStat = {phone: false, password: false};
function MyProfile() {
  const { id } = jwt_decode(localStorage.usertoken);
  const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
  const [profileDetails, setProfileDetails] = useState({});
  const [profileEdit, setProfileEdit] = useState(false);
  const [modalStat, setModalStat] = useState(initialModalStat);
  const masterData = useSelector(state => state.MasterDataReducer.MasterData);
  const [editDetails, setEditDetails] = useState({});
  
  useEffect(() => {
    if(profileDetails.email) {
      setEditDetails(editProfileData(profileDetails))
    }
  }, [profileDetails])

  useEffect(() => {
    fetchProfileData();
  }, [])

  const fetchProfileData = () => {
    const fetchdata = async () => {
      const params = { id: id };
      return getProfileDetails(headers, params);
    };
    
    fetchdata().then((userData) => {
      if((userData.status === 200) && userData?.data?.data) {
        setProfileDetails(userData.data.data)
      }  
    }).catch((err) => console.log(err))
  }


  const getSubject = (id = '') => {
    const subject = masterData?.subjectdata?.find(a => a._id === id);
    return subject ? subject.subjectName : '';
  }

  const handleModalOpen = (type = 'phone', open = false) => {
    setModalStat({...initialModalStat, [type]: open})
    if(type === 'phone' && !open) {
      fetchProfileData();
    }
  }

  const handleEdit = (e, cancel = false) => {
    e.preventDefault();
    setProfileEdit(!profileEdit);
    setEditDetails(editProfileData(profileDetails));
  }

  const handleChatBot = (e) => {
    e.preventDefault();
    window.zE('messenger', 'open');
  }

  return (
    <div className="centerSection">
      <PhoneUpdate
        headers={headers}
        id={id}
        modalStat={modalStat}
        handleModalOpen={handleModalOpen}
      />
      <PasswordUpdate headers={headers} id={id} show={modalStat.password} handleClose={handleModalOpen} />

      <div className="previewContent pageWrap">
        <div className="pageLeft">
          <Sidebar />
        </div>
        <div className="pageRight text-initial">
          <div className="previewProfile">
            <div className="previewProfile-img">
              {" "}
              <img src={profileDetails.profilePicture} alt="#" />
            </div>
            <div className="previewProfile-info">
              <small>Publishing Editor</small>
              <div>
                <p>
                  <b className="icon-email" />
                  {profileDetails.email}
                </p>
                <p>
                  <b className="icon-call" />
                  +91-{profileDetails.phoneNumber}{" "}
                  <a
                    className="updateLink"
                    onClick={(e) => {e.preventDefault(); handleModalOpen('phone', true)}}
                  >
                    Update
                  </a>
                </p>
              </div>
            </div>
            {
              profileEdit ? (
                <div className="previewProfile-btn">
                  <button onClick={(e) => handleEdit(e, true)} className="button button-ntv" type="button">Cancel</button>
                  <a onClick={(e) => handleEdit(e, false)} className="button button-primary ms-2">SAVE</a>
                </div>
              ) : (
                  <div className="previewProfile-btn">
                    <button
                      className="button"
                      type="button"
                      onClick={(e) => handleModalOpen('password', true)}
                    >
                      Change Password
                    </button>
                    <a
                      onClick={(e) => {e.preventDefault(); setProfileEdit(!profileEdit)} }
                      className="button button-primary ms-2"
                      href="ag_my_personal_detail_edit.html"
                    >
                      <b className="icon-pencil" />
                      Edit Details
                    </a>
                  </div>

              )
            }
          </div>
          {
            profileEdit ? (
              <EditProfile 
                masterData={masterData} 
                profileDetails={editDetails}
                getSubject={getSubject}
              />
            ) : (
                <Details
                  masterData={masterData} 
                  profileDetails={profileDetails}
                  getSubject={getSubject}
                />
              )
          }
        </div>
      </div>

      <div className="pullUp" onClick={(e) => window.scrollTo({top: 0, behavior: 'smooth'})}>
        <b className="icon-arrow-right" />
      </div>
      <div className="quickFAQ" onClick={(e) => handleChatBot(e)}>
        <p>Questions?</p>
        <span>Talk to Us</span>
      </div>
    </div>
  );
}

export default MyProfile;
