import HomeHeader from "./HomeHeader";
import ProfileSetupHeader from "./ProfileSetupHeader";
import { useLocation } from "react-router-dom";

function Header() {
  const { pathname } = useLocation()
  return (
    <>
      {
        ['/personaldetails', '/previewprofile'].indexOf(pathname.toLocaleLowerCase()) > -1 ? <ProfileSetupHeader /> : <HomeHeader />
      }
    </>
  );
}

export default Header;
