import Logo from "../../components/logo/Logo";
import { Link } from "react-router-dom";
import "./index.scss";

function Footer() {
    return <>
        <div className="footer-container">
            <footer>
                <div className="row">
                    <div className="col-lg-4 text-start">
                        <Logo />
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        <p className="text-muted">Copyright 2022</p>
                    </div>
                    <div className="col-lg-2">
                        <h3>VIKRAMSHILA</h3>
                        <ul>
                            <li><Link to="#">About Us</Link></li>
                            <li><Link to="#">Contact Us</Link></li>
                            <li><Link to="#">Mega Journals</Link></li>
                            <li><Link to="#">Participating Institutes</Link></li>
                            <li><Link to="#">Resources</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-2">
                        <h3>SERVICES</h3>
                        <ul>
                            <li><Link to="#">For Service Providers</Link></li>
                            <li><Link to="#">Browse Services</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-2">
                        <h3>MORE</h3>
                        <ul className="">
                            <li><Link to="#">Terms of Use</Link></li>
                            <li><Link to="#">Privacy Policy</Link></li>
                            <li><Link to="#">Refund Policy</Link></li>
                            <li><Link to="#">Disclaimers</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-2">
                        <h3 className="hidden">MORE</h3>
                        <ul className="text-lg-end text-sm-start">
                            <li><Link to="#"><i className="fa fa-facebook"></i></Link></li>
                            <li><Link to="#"><i className="fa fa-instagram"></i></Link></li>
                            <li><Link to="#"><i className="fa fa-twitter"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </div>
    </>
}

export default Footer