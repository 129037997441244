/*import "../../../assets/scss/base_app.scss";
import "../../../assets/scss/modules/_header_app.scss";
import "../../../assets/scss/modules/_menu_app.scss";
import "../../../assets/scss/sections/_profile_app.scss";*/

import { affiliationTitle, getSpecializationNames } from "../../../utils";
import StateJson from "../../../constants/States.json";
import SearchDropdown from "../../../components/SearchDropdown/SearchDropdown";
import { useState } from "react";
import { useEffect } from "react";

const EditProfile = ({ profileDetails = {}, masterData = {}, getSubject = () => {}}) => {
    const [userDetails, setUserDetails] = useState(profileDetails);
    useEffect(() => {
        setUserDetails({...userDetails, profileDetails})
    }, [profileDetails])

    const { Education, email, fullName, gender, title, workType, affiliation } = userDetails;
    const getSpecializationOptions = (subjectId = "") => {
        const subData = masterData.subjectdata.find(a => a._id === subjectId);
        return subData.specializationId ? subData.specializationId?.map(a => {
            return <option key={a._id} value={a._id}>{a.specialization}</option>;
        }) : [];
    }

    const getSubjectOptions = () => {
        return masterData.subjectdata.map(a => {
            return <option key={a._id} value={a._id}> {a.subjectName} </option>
        })
    }

    const handleChange = (e) => {
        const { type, value, name, checked } = e.target;
        if (type === 'radio') {
            setUserDetails({...userDetails, [name]: checked ? value : ""})
        } else {
            setUserDetails({...userDetails, [name]: value})
        }
    }

    const getClonedDetails = () => {
        return JSON.parse(JSON.stringify(userDetails));
    }
    const handleAffiliationChange = (e, aff = {}) => {
        const details = getClonedDetails();
        if(e.target.name === "affiliation_type" ) {
            details.affiliation.affiliation_type = e.target.checked ?  e.target.value : ''
            setUserDetails(details)
        } else {
            const affRecord = details.affiliation.affiliations.find(a => a._id === aff.id);
            if(affRecord) {
                affRecord[e.target.name] = e.target.value;
                setUserDetails(details)
            }
        }
    }

    const handleEducationChange = (e) => {
        const details = getClonedDetails();
        details.Education[e.target.name] = e.target.value;
        setUserDetails(details)
    }

    const handleSubjectInterestChange = (e, id) => {
        const details = getClonedDetails();
        const subData = details.subjectOfInterest.find(sub => sub.interestedsubject === id)
        if(subData) {
            subData[e.target.name] = e.target.value
        }
        setUserDetails(details);
    }

    return(
        <>
            <h1 className="labelHeading pb-0">Personal Details</h1>
            <div className="boxCover noStyle pt-0">
                <div className="fieldSet border-0">
                <ul>
                    <li>
                    <div className="fieldName mb-3">
                        <div className="fieldWrap">
                        <label className="labelForm">Title</label>
                        <div>
                            <select className="fieldForm">
                            <option>{title}</option>
                            </select>
                        </div>
                        </div>
                        <div className="fieldWrap">
                        <label className="labelForm">Full Name</label>
                        <div>
                            <input onChange={handleChange} name="fullName" value={fullName} className="fieldForm" type="text" />
                        </div>
                        </div>
                    </div>
                    </li>
                    <li>
                    <div className="fieldWrap mb-3">
                        <label className="labelForm">Gender</label>
                        <div>
                        <div className="d-inline mx-4 ms-0">
                            <label className="styledRadio">
                            <input onChange={handleChange} type="radio" value="Male" name="gender" checked={gender === 'Male' } />{" "}
                            <span>Male</span>
                            </label>
                        </div>
                        <div className="d-inline mx-4 ms-0">
                            <label className="styledRadio">
                            <input onChange={handleChange} type="radio" name="gender" value="Female" checked={gender === 'Female' }/> <span>Female</span>{" "}
                            </label>
                        </div>
                        <div className="d-inline mx-4 ms-0">
                            <label className="styledRadio">
                            <input onChange={handleChange} value="Gender Fluid" type="radio" name="gender" checked={gender === 'Gender Fluid' }/>{" "}
                            <span>Gender Fluid</span>{" "}
                            </label>
                        </div>
                        <div className="d-inline mx-4 ms-0">
                            <label className="styledRadio">
                            <input onChange={handleChange} value="Others" type="radio" name="gender" checked={gender === 'Others'}/> <span>Others</span>{" "}
                            </label>
                        </div>
                        </div>
                    </div>
                    </li>
                    <li>
                        <div className="fieldWrap mb-3">
                            <label className="labelForm">Email ID</label>
                                <div className="fieldCover">
                                <b className="icon-email" />
                                <input onChange={handleChange} name="email" className="fieldForm" value={email} type="email" />
                            </div>
                        </div>
                    </li>
                </ul>
                </div>
            </div>
            <h2 className="labelHeading">Professional Details</h2>
            <div className="boxCover noStyle pt-0">
                <div className="fieldSet">
                <h3 className="labelSubHeading mb-3">Work Type</h3>
                <ul>
                    <li className="fullWidth">
                    <div className="fieldWrap">
                        <div>
                        <div className="d-inline mx-4 ms-0">
                            <label className="styledRadio">
                            <input onChange={handleChange} type="radio" name="Work" value="Academic" checked={workType === 'Academic'} />{" "}
                            <span>Academic</span>{" "}
                            </label>
                        </div>
                        <div className="d-inline mx-4 ms-0">
                            <label className="styledRadio">
                            <input onChange={handleChange} type="radio" name="workType" value="Medical & Health Sciences" checked={workType === 'Medical & Health Sciences'}/>{" "}
                            <span>Medical &amp; Health Sciences</span>
                            </label>
                        </div>
                        <div className="d-inline mx-4 ms-0">
                            <label className="styledRadio">
                            <input onChange={handleChange} type="radio" name="workType" value="Researcher" checked={workType === "Researcher"} /> <span>Researcher</span>{" "}
                            </label>
                        </div>
                        <div className="d-inline mx-4 ms-0">
                            <label className="styledRadio">
                            <input onChange={handleChange} type="radio" name="workType" value="Professional" checked={workType === "Professional"} />{" "}
                            <span>Professional</span>{" "}
                            </label>
                        </div>
                        <div className="d-inline mx-4 ms-0">
                            <label className="styledRadio">
                            <input onChange={handleChange} type="radio" name="workType" value="Student" checked={workType === "Student"} /> <span>Student</span>{" "}
                            </label>
                        </div>
                        </div>
                    </div>
                    </li>
                </ul>
                </div>
                <div className="fieldSet">
                    {
                        affiliation?.affiliations?.map((aff, index) => {
                            return(
                                <>
                                    <h3 className="labelSubHeading mb-3">{affiliationTitle[index + 1]}</h3>
                                    <ul>
                                        {
                                            index < 2 && (
                                                <li>
                                                    <div className="fieldWrap mb-4">
                                                        <div className="fieldWrap">
                                                        <div>
                                                            <div className="d-inline mx-4 ms-0">
                                                            <label className="styledRadio">
                                                                <input onClick={(e) => handleAffiliationChange(e)} value="University" type="radio" name="affiliation_type" checked={affiliation.affiliation_type === "University"} />
                                                                <span>University</span>{" "}
                                                            </label>
                                                            </div>
                                                            <div className="d-inline mx-4 ms-0">
                                                            <label className="styledRadio">
                                                                <input onChange={(e) => handleAffiliationChange(e)} value="Institute" type="radio" name="affiliation_type" checked={affiliation.affiliation_type === "Institute"}/>{" "}
                                                                <span>Institute</span>{" "}
                                                            </label>
                                                            </div>
                                                            <div className="d-inline mx-4 ms-0">
                                                            <label className="styledRadio">
                                                                <input onChange={(e) => handleAffiliationChange(e)} value="Company" type="radio" name="affiliation_type" checked={affiliation.affiliation_type === "Company"}/>{" "}
                                                                <span>Company</span>{" "}
                                                            </label>
                                                            </div>
                                                            <div className="d-inline mx-4 ms-0">
                                                            <label className="styledRadio">
                                                                <input onChange={(e) => handleAffiliationChange(e)} value="Hospital" type="radio" name="affiliation_type" checked={affiliation.affiliation_type === "Hospital"}/>{" "}
                                                                <span>Hospital</span>{" "}
                                                            </label>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        }
                                        <li>
                                            <div className="fieldCol"></div>
                                        </li>
                                        <li>
                                            <div className="fieldWrap mb-3">
                                                <label className="labelForm">Name of university</label>
                                                <div>
                                                <input
                                                    className="fieldForm"
                                                    type="text"
                                                    value={aff.name}
                                                    name="name"
                                                    onChange={(e) => handleAffiliationChange(e, aff)}
                                                />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                        <div className="fieldWrap mb-3">
                                            <label className="labelForm">University Website </label>
                                            <div className="custom_input">
                                                <input
                                                    className="fieldForm"
                                                    type="url"
                                                    value={aff.url}
                                                    name="url"
                                                    onChange={(e) => handleAffiliationChange(e, aff)}
                                                />
                                                <div className="input_post link" dangerouslySetInnerHTML={{__html: aff.urlVerified ? `<span style='padding: 0 18px;'><Link to='#' >Verify</Link> </span>` : "EDIT" }}/>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className="fieldWrap mb-3">
                                            <label className="labelForm">Designation</label>
                                            <div>
                                            <input
                                                className="fieldForm"
                                                type="text"
                                                value={aff.department}
                                                name="department"
                                                onChange={(e) => handleAffiliationChange(e, aff)}
                                            />
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className="fieldWrap mb-3">
                                            <label className="labelForm">Primary Subject</label>
                                            <div>
                                            <select className="fieldForm" name="subject" onChange={(e) => handleAffiliationChange(e, aff)} value={aff.subject}>
                                                <option>Select a value.</option>
                                                {getSubjectOptions()}
                                            </select>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className="fieldWrap mb-3">
                                            <label className="labelForm">Specialization</label>
                                            <div>
                                                <SearchDropdown
                                                    options={getSpecializationOptions(aff.subject)}
                                                    labelClass={"hidden"}
                                                    name="specialization"
                                                    placeholder={"Specialization"}
                                                    label="Specialization (include upto 3)"
                                                />
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className="fieldWrap mb-3">
                                            <label className="labelForm">City</label>
                                            <div>
                                                <select className="fieldForm" name="city" value={aff.city} onChange={(e) => handleAffiliationChange(e, aff)}>
                                                    <option>Select a value.</option>
                                                    {StateJson[aff.state] ? StateJson[aff.state]?.map((city) => {
                                                        return <option key={city} value={city} >{city}</option>
                                                    }) : <option></option>}
                                                </select>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className="fieldWrap mb-3">
                                            <label className="labelForm">State</label>
                                            <div>
                                            <select className="fieldForm" value={aff.state} onChange={(e) => handleAffiliationChange(e, aff)} name="state">
                                                <option>Select a value.</option>
                                                {Object.keys(StateJson)?.sort().map(record => {
                                                    return <option key={record} value={record}>{record}</option>
                                                })}
                                            </select>
                                            </div>
                                        </div>
                                        </li>
                                        <li>
                                        <div className="fieldWrap mb-3">
                                            <label className="labelForm">Pin Code</label>
                                            <div>
                                            <input
                                                className="fieldForm"
                                                type="number"
                                                value={aff.pincode}
                                                name="pincode"
                                                onChange={(e) => handleAffiliationChange(e, aff)}
                                            />
                                            </div>
                                        </div>
                                        </li>
                                    </ul>
                                </>
                            )
                        })
                    }
                </div>
                <div className="fieldSet">
                <h3 className="labelSubHeading mb-3">Education</h3>
                <ul>
                    <li>
                        <div className="fieldWrap mb-3">
                            <label className="labelForm">Qualification</label>
                            <div>
                                <select className="fieldForm" value={Education.qualification} name="qualification" onChange={(e) => handleEducationChange(e)}>
                                    <option>Select a value.</option>
                                    {masterData.qualificationdata?.map(record => {
                                        return <option key={record._id} value={record._id} > {record.qualification}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </li>
                    <li>
                    <div className="fieldWrap mb-3">
                        <label className="labelForm">Subject</label>
                        <div>
                            <select className="fieldForm" value={Education.subject} name="subject" onChange={(e) => handleEducationChange(e)}>
                                <option>Select a value.</option>
                                {getSubjectOptions()}
                            </select>
                        </div>
                    </div>
                    </li>
                    <li>
                    <div className="fieldWrap mb-3">
                        <label className="labelForm">Specialization</label>
                        <div>
                            <SearchDropdown
                                options={getSpecializationOptions(Education.subject)}
                                labelClass={"hidden"}
                                name="specialization"
                                placeholder={"Specialization"}
                                label="Specialization (include upto 3)"
                            />
                        </div>
                    </div>
                    </li>
                </ul>
                </div>
                <div className="fieldSet">
                    <h3 className="labelSubHeading mb-3">Subject of Interest</h3>
                    <ul>
                        {
                            userDetails.subjectOfInterest.map(interest => {
                                return(
                                    <>
                                        <li key={interest.interestedsubject}>
                                            <div className="fieldWrap mb-3">
                                                <label className="labelForm">Subject</label>
                                                <div>
                                                    <select className="fieldForm" value={interest.interestedsubject} name="interestedsubject" onChange={(e) => handleSubjectInterestChange(e,interest.interestedsubject)}>
                                                        <option>Select a value.</option>
                                                        {getSubjectOptions()}
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="fieldWrap mb-3">
                                                <label class="labelForm">Specialization</label>
                                                <div>
                                                    <SearchDropdown
                                                        options={getSpecializationOptions(interest.interestedsubject)}
                                                        labelClass={"hidden"}
                                                        name="specialization"
                                                        placeholder={"Specialization"}
                                                        label="Specialization (include upto 3)"
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    </>
                                )
                            })
                        }
                    </ul>
                    {/* <div className="fieldMore pt-0">
                        <a>+ Add Another</a>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default EditProfile;