import {
  GET_PROFILE_DETAILS,
  GET_OTHER_SUBJECT,
  SAVE_SUBJECT_STATUS,
} from "../actions/profileActions";

const initialState = {
  userLoginDetails: {
    token: "",
  },
  user: {},
  otherSubject: [],
  saveStatus: "",
};

const ProfileReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PROFILE_DETAILS:
      return {
        ...state,
        user: action.payload.data,
      };
    case GET_OTHER_SUBJECT:
      return {
        ...state,
        otherSubject: action?.payload,
      };
    case SAVE_SUBJECT_STATUS:
      return {
        ...state,
        saveStatus: action?.payload,
      };
    default:
      return state;
  }
};
export default ProfileReducer;
