const ApiEndPoint = {
  AakashGanga: "/AakashGanga",
  Registration: "/Registration",
  VerifyUser:'/verifyUser',
  UserLogin: "/userLogin",
  Social: "/socialLogin",
  ForgotPassword: "/forgotPassword",
  ResetPassword: "/resetPassword",
  GetUserById: "/getUserById",
  Profile_setup: "/profile_setup",
  Verifyotp:"/verify_otp",
  ChangePassword:"/changePassword",
  GetAllMasterData:'/getAllMasterData',
  GetSpecialtiozation:'/getSpecialtiozation',
  SaveArticle:'/saveArticle',
  GetArticleByUserId:'/getArticleByUserId',
  GetArticleById:'/getArticleById',
  UpdateArticle:'/updateArticle',
  ResendOtpForVerification:'/resendOtpForVerification',
  VerifyUrl:'/verifyUrl',
  RemoveProfilePicture:'/removeProfilePicture',
  SubmitFeedback: '/submitFeedback',
  UPDATEMOBILE: '/changePhoneNumber',
  UPDATEMOBILEOTPVERIFY: '/verifyPhoneNumber',
  ADD_SPECIALIZATION: '/addOtherSpecialization',
  UPDATEPROFILEPASSWORD: '/changePassword',
  UploadMedia: '/uploadMedia',
  SUBSCRIBE: '/countMeIn',
  GET_SUBJECT_SPECIALIZATION: '/getSubjectSpecilisation',
  ADD_OTHER_SPECIALIZATION: '/addOtherSpecializationVersion1',
  GET_OTHER_SPECIALIZATION: '/addOtherSpecializationStatus'
};

Object.freeze(ApiEndPoint);
export default ApiEndPoint;
