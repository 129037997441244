import Header from "../Homepage/header";
import Footer from "../Homepage/Footer";
import ReactPlayer from "react-player/youtube";
import arrowLeft from "../../assets/img/icons/arrow_left.svg";
import { useNavigate } from "react-router-dom";
const BrowseVideo = () => {
    const navigate = useNavigate();
    const handleRedirect = (e, path = "/registration") => {
        e.preventDefault();
        window.scroll({top: 0, behavior: 'smooth'});
        navigate(path)
    }

    const handleChatBot= (e) => {
        e.preventDefault();
        window.zE('messenger', 'open');
    }
    
    return(
        <>
            <Header />
            <div className="resourceSection innerPages">
                <div className="containWrap">
                    <div className="resourceWrap">
                    <h1>
                        Browse <b>Videos</b>
                    </h1>
                    </div>
                    <div className="videoList">
                    <ul>
                        <li>
                        <div className="videoBox borderBox">
                            <div className="videoBox-placeholder">
                            {/* <ReactPlayer 
                                url="https://www.youtube.com/watch?v=Iewt4-w2R9g"
                                playing={false}
                                controls={true}
                                loop={true}
                                playsinline={true}
                                width="100%"
                            /> */}
                                <iframe width="362" height="315" className="w-100" src="https://www.youtube.com/embed/sySB0yvpqE8?si=O9YQOxZUkaKJXcoE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay=1; loop=1 clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true"></iframe>
                            </div>
                            <div className="videoBox-text">
                            <a href="https://www.youtube.com/watch?v=sySB0yvpqE8" target="_blank">Message from the Founder</a>
                            <small>3:07</small>
                            </div>
                        </div>
                        </li>
                        <li>
                        <div className="videoBox borderBox">
                            <div className="videoBox-placeholder">
                                <iframe width="362" height="315" className="w-100" src="https://www.youtube.com/embed/w68GPd_pliU?si=eM6HS2L6SzUT52bc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay=1; loop=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true"></iframe>
                            </div>
                            <div className="videoBox-text">
                            <a href="https://www.youtube.com/watch?v=w68GPd_pliU" target="_blank">Know how to register</a>
                            <small>3:53</small>
                            </div>
                        </div>
                        </li>
                        <li>
                        <div className="videoBox borderBox">
                            <div className="videoBox-placeholder">
                                <iframe width="362" height="315" className="w-100" src="https://www.youtube.com/embed/IaPrg-5O-Ks?si=SOkK0XUcwvFDM5hL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay=1; loop=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true"></iframe>
                            </div>
                            <div className="videoBox-text">
                            <a href="https://www.youtube.com/watch?v=IaPrg-5O-Ks&t=3s" target="_blank">Author Submission Guidelines</a>
                            <small>9:02</small>
                            </div>
                        </div>
                        </li>
                        <li>
                        <div className="videoBox borderBox">
                            <div className="videoBox-placeholder">
                                <iframe width="362" height="315" className="w-100" src="https://www.youtube.com/embed/4blR1Jz1bms?si=rr-wmjosy2DdUmgE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay=1; loop=1; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true"></iframe>
                            </div>
                            <div className="videoBox-text">
                            <a href="https://www.youtube.com/watch?v=4blR1Jz1bms" target="_blank">Seva Haat—Earn with Us</a>
                            <small>1:35</small>
                            </div>
                        </div>
                        </li>
                    </ul>
                    </div>
                    <div className="row align-items-center justify-content-between contentFooter">
                    <div className="col-auto">
                        <a onClick={(e) => handleRedirect(e,'/resourcecentre')} className="goBack" href="resource_centre.html">
                            Back
                        <b>
                            <img src={arrowLeft} alt="#" />
                        </b>
                        </a>
                    </div>
                    <div
                        className="pullUp"
                        onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
                    >
                        <b className="icon-arrow-right" />
                    </div>
                    <div className="col-auto text-end">
                        <a className="helpLink" onClick={handleChatBot}>
                        <b className="icon-help" />
                        Help
                        </a>
                    </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}


export default BrowseVideo;