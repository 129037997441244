import React, { useState } from "react";
import Select from "../common/Select";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import { removeOthers } from "../../utils";
import { getSubjectSpecialization } from "../../store/apiCalls/profileDetails";

const SubjectOfIntrestSection = ({
  subjectOfIntrestSection,
  masterData,
  handleSubjectOfIntrestChange,
  handleSelectChange = () => {},
  activeTab,
  handleOthers = () => {},
}) => {
  const handleSubjectSelectChange = (value, sectionId, type) => {
    handleSelectChange(value, type, sectionId);
  };

  const [subjectOptions, setSubjectOptions] = useState({});

  const getClasses = () => {
    return "form-group mb-4";
  };

  const getSpecOptions = async (val) => {
    const optionData = removeOthers(masterData?.MasterData?.subjectdata, val?.subject);
    const headers = { Authorization: `Bearer ${localStorage?.usertoken}` };
      const params = {
        subjectId: optionData
      };
      const response = await getSubjectSpecialization(headers, params);
      if (response?.status === 200) {
        let newRecord = response?.data?.data?.data
        let specOpt = newRecord?.filter((spec) => spec?.specialization !== "Other Subject (choose this option to register your subject)").map((spec) => spec?.specialization);
        setSubjectOptions((soi) => ({ ...soi, [val?.subject]: specOpt }));
      } else return;
    };

  return (
    <div className="col-lg-11 col-pd">
      <div className="row">
        <p>Subject of Interest</p>
        <p className="txtSmall mb-36">You can add upto 3</p>
        {subjectOfIntrestSection.map((val, ind) => {
          return (
            <div key={ind} className="row">
              <div className="col-lg-6">
                <div className={getClasses()}>
                  <Select
                    options={masterData?.MasterData?.subjectdata?.map(
                      (val) => val.subjectName
                    )}
                    name="interestedsubject"
                    placeholder="Discipline"
                    handleChange={(value) =>
                      handleSubjectSelectChange(value, ind, "interestedsubject")
                    }
                    selectedValue={val?.subject}
                    labelClass={val?.subject ? "" : "hidden"}
                    label="Discipline"
                    required={activeTab === "professional" ? true : false}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className={getClasses()}>
                  <div className="position-relative">
                    <SearchDropdown
                      options={subjectOptions[val?.subject] ?? (getSpecOptions(val), [])}
                      disabled={!val?.subject}
                      index={ind}
                      keyName="subject"
                      onChange={(e) => handleSubjectOfIntrestChange(e, ind)}
                      selectedValue={val?.specialization}
                      labelClass={
                        val?.specialization.length !== 0 ? "" : "hidden"
                      }
                      handleOtherOption={(data) => handleOthers(data, ind)}
                      name="Interested Specialization"
                      selected="Subject"
                      placeholder={
                        val?.specialization?.length === 0
                          ? "Subject"
                          : val?.specialization.length > 1
                          ? val?.specialization[0] +
                            " +" +
                            String(val?.specialization.length - 1)
                          : val?.specialization[0]
                      }
                      label="Subject (include upto 3)"
                    />
                    
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubjectOfIntrestSection;