import { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateProfilePassword } from "../../../store/apiCalls/profileDetails";
import doneSvg from "../../../assets/img/icons/done.svg";

const PasswordUpdate = ({ show = true, handleClose = () => {}, headers = {}, id = ""}) => {
    const [error, setError] = useState(null);
    const [data, setData] = useState({password: '', confirmPassword: ''});
    const [showCongrats, setShowCongrats] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(data.password === data.confirmPassword) {
            const resp = await updateProfilePassword({data: {newPassword: data.password, reEnterPassword: data.confirmPassword}, id, headers});
            if(resp.data.success) {
                handleClose('phone', false)
                setShowCongrats(true);
            } else {
                setError({mobileError: resp.data.message})
            }
        } else {
            setError("Password confirm doesn't match");
        }
    }

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    }

    const handlePasswordClose = (e) => {
        e.preventDefault();
        setShowCongrats(false);
        handleClose('password', false)
    }

    return(
        <>
            <Modal show={show} dialogClassName="modal-dialog modal-dialog-centered modal-sm" id="updatePassword" tabIndex={-1}>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Modal.Header className="modal-header">
                        <h5 className="modal-title">Change Password</h5>
                        <p>Please enter your new desirable password</p>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={(e) => handleClose('password', false)}
                        />
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className="px-2 pt-1">
                            <div className="fieldWrap">
                                <label className="labelForm">New Password</label>
                                <div className="fieldCover">
                                    <b className="icon-lock" />
                                    <input
                                        pattern="^(?=(?:.*[a-z]){1,})(?=(?:.*[A-Z]){1,})(?=(?:.*\d){1,})(?=(?:.*[!@#$%^&*-]){1,}).{8,}$"
                                        title="Password should be of 8 character with one number, one capital letter and a special character." 
                                        className="fieldForm" 
                                        type="password" name="password" onChange={handleChange} required />
                                </div>
                            </div>
                            <div className="fieldWrap mt-3">
                                <label className="labelForm">Re-enter New Password</label>
                                <div className="fieldCover">
                                    <b className="icon-lock" />
                                    <input className="fieldForm" type="password" name="confirmPassword" onChange={handleChange} required/>
                                </div>
                            </div>
                            {
                                error && (
                                    <div className="fieldWrap mt-3">
                                        <p className="error-msg">{error}</p>
                                    </div>
                                )
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <button type="submit" onClick={(e) => handleClose('password', false)} className="button button-ntv">
                            Cancel
                        </button>
                        <button className="button button-primary">
                            Confirm
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
                <Modal dialogClassName="modal-dialog-centered modal-sm" show={showCongrats} id="congratsMobile" tabIndex={-1}>
                <Modal.Header className="modal-header">
                    <div class="modal-icon"> <img src={doneSvg} alt="#" /></div>
                    <h5 class="modal-title">Password Updated</h5>
                    <button type="button" class="btn-close" ariaLabel="Close" onClick={handlePasswordClose}></button>
                </Modal.Header>

                <Modal.Body className="modal-body">
                    <div class="pt-0 text-center simpleText">
                        <p>Your password has been changed successfully</p>
                    </div>
                </Modal.Body>

                <Modal.Footer className="modal-footer">
                    <button class="button button-primary w-50" onClick={handlePasswordClose}>Done</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PasswordUpdate;