import React from "react";
import Modal from "react-bootstrap/Modal";

const BeforePublishModal = ({ showAccept, setShowAccept, title, body, btnTxt }) => {
  return (
    <Modal
      dialogClassName="modal-dialog-centered modal-md authorModal md-x"
      show={showAccept}
      id="congratsMobile"
      tabIndex={-1}
      className="md-x"
    >
      <Modal.Header className="modal-header">
        <h2 class="modal-title">{title}</h2>
        <button
          type="button"
          class="btn-close"
          ariaLabel="Close"
          onClick={() => setShowAccept(false)}
        ></button>
      </Modal.Header>

      <Modal.Body className="pt-0">
        <div
          className="aaforAss"
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        />
      </Modal.Body>

      <Modal.Footer className="modal-footer pb-4">
        <button
          class="button button-primary button-sm"
          onClick={() => setShowAccept(false)}
        >
          {btnTxt}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BeforePublishModal;