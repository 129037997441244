export const PATH = {
    ABOUT: '/about',
    HOMEPAGE: '/',
    LOGIN: '/login',
    FORGOTPASSWORD: '/forgotpassword',
    MOBILELOGIN: '/mobilelogin',
    REGISTRATION: '/registration',
    RESETPASSWORD: '/reset-password/:token',
    VERIFYOTP: '/verifyotp',
    VERIFYEMAIL:'/activate/:token',
    LANDINGPAGE: '/landingpage',
    AUTHER_FLOW_DASHBOARD: '/dashboard',
    PERSONAL_DETAILS:'/personaldetails',
    PAYMENT_DETAILS:'/paymentdetails',
    AUTHORDASHBOARD:'/authorDashboard',
    MYPROFILE: '/myprofile', 
    EDITPROFILE: '/editprofile',
    SUBMITARTICLE: '/submitarticle',
    EDITARTICLE:'/editarticle/:id',
    POPUPSCREENS:'/popoupscreens',
    PREVIEWPROFILE:'/previewprofile',
    CONTACT_US: '/contactus',
    RESOURCE_CENTER: '/resourcecentre',
    PLAY_LIST: '/playlist',
    MEDIA_COVERAGE: '/media-coverage',
    PRESENTATIONS: '/presentations',
    FAQ: '/faq',
    COOKIE: '/cookie',
    COUNSIL: '/council',
    ADVISORY: '/advisory',
    WIP: '/work-in-progress-kaashi',
    WIP_AKASHGANGA: '/work-in-progress-akashganga',
    BROWSE: '/browse',
    MEMBER_DETAILS: '/member-details',
    TERMS: '/terms',
    PRIVACY_POLICY: '/privacy-policy',
    TEAMS: '/teams',
    FOUNDER_DETAILS: '/founder-details',
    ERROR: '*',
    MEMBERS: {
        JAGDISH_SETH: '/member-details/jagdish-seth',
        AMITABH_RAJAN: '/member-details/amitabh-rajan',
        PROF_GAUR: '/member-details/prof-ramesh-gaur',
        RAJIV_BHATIA: '/member-details/rajiv-bhatia',
        BHIMARYA_MATRI: '/member-details/bhimaraya-matri',
        HARIVANSH_CHATURVEDI: '/member-details/harivansh-chaturvedi',
        SUCHIRA: '/member-details/suchiradipta-bhattacharjee',
        SUBIR: '/member-details/subir-verma',
        SANJAY_SHRIVASTAVA: '/member-details/sanjay-shrivastava',
        // SADHANA_RAUT: '/member-details/sadhana-raut',
        RISHI_KALPAL: '/member-details/rishi-kalpal',
        NANDINI: '/member-details/nandini',
        BHARAT_WAKLU: '/member-details/bharat-waklu',
        ASHA: '/member-details/asha',
        ANMOL_PANDIT: '/member-details/anmol-pandit',
        PRITAM_SINGH: '/member-details/late-pritam-singh',
        SID_MITRA: '/member-details/late-sid-mitra',
        SRIPANA_BASU: '/member-details/sriparna-basu',
        KUMARASWAMY: '/member-details/kumaraswamy',
        SAM: '/member-details/sam',
        NEERAJ_KAPOOR: '/member-details/neeraj-kapoor',
        RUPAMANJARI_GHOSH: '/member-details/rupamanjari-ghosh',
        ANKUR_SEGON: '/member-details/ankur-segon',
        RAJIV_SINGLA: '/member-details/rajiv-singla'
    }
}