import { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { updateMobileNumber, updateMobileNumberOTPVerify } from "../../../store/apiCalls/profileDetails";
import doneSvg from "../../../assets/img/icons/done.svg";

const PhoneUpdate = ({modalStat = {}, handleModalOpen = () => {}, headers = {}, id = ""}) => {
    const [phone, setPhone] = useState(null);
    const [showOTP, setShowOTP] = useState({otp: false, congrats: false});
    const [OTP, setOTP] = useState(null);
    const [timer, setTimer] = useState("00:00");
    const [error, setError] = useState({});
    const Ref = useRef(null);

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
          total,
          minutes,
          seconds,
        };
      };
    
    const startTimer = (e) => {
        let { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimer((minutes > 9 ? minutes : "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds));
        }
    };

    const clearTimer = (e) => {
        setTimer("00:30");

        if (Ref.current) clearInterval(Ref.current);

        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 30);
        deadline.setMinutes(deadline.getMinutes() + 0);
        return deadline;
    };

    useEffect(() => {
        if(showOTP.otp) {
            clearTimer(getDeadTime());
            // eslint-disable-next-line
        }
    }, []);

    const handleOTPClose = (e) => {
        e.preventDefault();
        setShowOTP({otp: false, congrats: false});
        handleModalOpen('phone', false)
        setOTP(null);
        setPhone(null);
    }

    const handleMobileSubmit = async (e) => {
        e.preventDefault();
        if(phone && phone.toString().length !== 10) {
            setError({mobileError: "Please enter a valid phone number."})
            return;
        }

        const resp = await updateMobileNumber({data: {newPhoneNumber: phone}, id, headers});
        if(resp.data.success) {
            setShowOTP({otp: true})
            handleModalOpen('phone', false)
            setOTP(resp.data.otp)
        } else {
            setError({mobileError: resp.data.message})
        }
    }

    const handleOtpVerification = async (e) => {
        e.preventDefault();
        const resp = await updateMobileNumberOTPVerify({data: {otp: OTP}, id, headers});
        if(resp.status === 200 && resp.data.message ) {
            setShowOTP({otp: false, congrats: true})
        } else {
            setError({otpError: resp.data.message})
        }
    }

    const handleResendOTP = (e) => {
        e.preventDefault();
        clearTimer(getDeadTime());
    }

    return(
        <>
            <Modal show={modalStat.phone} id="updateMobile" dialogClassName="modal-dialog-centered modal-sm" tabIndex={-1}>
                <form onSubmit={handleMobileSubmit}>
                    <Modal.Header className="modal-header">
                        <h5 className="modal-title">Update Mobile Number</h5>
                        <p>OTP will be sent on your new mobile number</p>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={(e) => handleOTPClose(e)}
                        />
                    </Modal.Header>

                    <Modal.Body className="modal-body">
                        <div className="px-2 pt-1">
                            <div className="fieldWrap">
                                <label className="labelForm">Mobile Number</label>
                                <div className="fieldCover">
                                    <b className="icon-call" />
                                    <input value={phone} onChange={(e) => setPhone(e.target.value)} className="fieldForm" type="number" required/>
                                    <p className="error-msg">{error.mobileError}</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <button onClick={(e) => handleModalOpen('phone', false)} className="button button-ntv">
                            Cancel
                        </button>
                        <button
                            className="button button-primary"
                            type="submit"
                        >
                        Verify
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal dialogClassName="modal-dialog-centered modal-sm" show={showOTP.otp} id="verifyMobile" tabIndex={-1}>
                <form onSubmit={(e) => handleOtpVerification(e)}>
                    <Modal.Header className="modal-header">
                        <h5 className="modal-title">Verify Mobile Number</h5>
                        <p>Please enter the OTP sent to your mobile number</p>
                        <button
                            type="button"
                            className="btn-close"
                            ariaLabel="Close"
                            onClick={(e) => handleOTPClose(e)}
                        />
                    </Modal.Header>

                    <Modal.Body className="modal-body">
                        <div className="px-2 pt-1">
                            <div className="fieldWrap">
                                <label className="labelForm">OTP</label>
                                <div className="fieldCover">
                                    <input className="fieldForm" type="number" value={OTP} onChange={(e) => setOTP(e.target.value)} required />
                                    <p className="error-msg">{error.otpError}</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer className="modal-footer">
                        <button
                            type="submit"
                            className="button button-primary w-50"
                        >
                            Verify
                        </button>
                        <div className="otpInfo">
                            <p>{timer}s</p>
                            <small>
                                Not received OTP? {timer === "00:00" && (
                                    <a onClick={(e) => handleResendOTP(e)}>
                                        Resend OTP
                                    </a>
                                ) } 
                            </small>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
            
            <Modal dialogClassName="modal-dialog-centered modal-sm" show={showOTP.congrats} id="congratsMobile" tabIndex={-1}>
                <Modal.Header className="modal-header">
                    <div class="modal-icon"> <img src={doneSvg} alt="#" /></div>
                    <h5 class="modal-title">Mobile Number Updated</h5>
                    <button type="button" class="btn-close" ariaLabel="Close" onClick={handleOTPClose}></button>
                </Modal.Header>

                <Modal.Body className="modal-body">
                    <div class="pt-0 text-center simpleText">
                        <p>Your mobile number has been changed successfully</p>
                    </div>
                </Modal.Body>

                <Modal.Footer className="modal-footer">
                    <button class="button button-primary w-50" onClick={handleOTPClose}>Done</button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default PhoneUpdate;