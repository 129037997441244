import { useLocation, useNavigate } from "react-router-dom";
import ArticleRow from "../../../components/common/ArticleRow";
import SubmitArticleButton from "../../../components/common/SubmitArticleButton";
import SuccessModal from "../../../components/Modal/sucessModal";
import Footer from "../../../layout/footer";
import Header from "../../../layout/header";
import Sidebar from "../../../layout/sidebar";
import "./index.scss";
const AuthorDashboard = () => {
  const navigate = useNavigate();
  const loc = useLocation();
  return (
    <div className="header-author-layout">
      <div className="header-author">
        <Header />
      </div>

      <div className="content author-dashboard centerSection">
        <div className="row">
          <div className="col-lg-2 p-0">
            <Sidebar />
          </div>
          <div className="col-lg-10 text-start p-4">
            <div className="row">
              <div className="col-lg-8">
                <p>
                  <i className="fa fa-th-large me-2"></i>Dashboard
                </p>
                <div className="row">
                  <div className="col-lg-7">
                    <p className="m-0">Get your article the reach it needs!</p>
                    <small>Upload your article in three easy steps</small>
                  </div>
                  <div className="col-lg-5">
                    <SubmitArticleButton />
                  </div>
                </div>
                <hr />
                <div>
                  <ul className="nav article-nav">
                    <li className="nav-item">Drafts</li>
                    <li className="nav-item">Under Review</li>
                    <li className="nav-item">Approved</li>
                  </ul>
                </div>
                <div></div>
                <p>
                  <small>My Articles</small>
                </p>
                <ArticleRow />
              </div>
              <div className="col-lg-4">
                <p>Recommended Articles</p>
                <div
                  id="carouselArticleIndicators"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#carouselArticleIndicators"
                      data-bs-slide-to="0"
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#carouselArticleIndicators"
                      data-bs-slide-to="1"
                      aria-label="Slide 2"
                    ></button>
                  </div>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <ArticleRow />
                      <ArticleRow />
                    </div>
                    <div className="carousel-item">
                      <ArticleRow />
                      <ArticleRow />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselArticleIndicators"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselArticleIndicators"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <SuccessModal
          upperInformationText={
            "Congratulations! You have been logged in successfully."
          }
          show={loc?.state?.openSuccessLoginModal}
          submitButtonTitle={"Ok"}
          onSubmit={() => {
            navigate("/authorDashboard");
          }}
        /> */}
      </div>
      <Footer />
    </div>
  );
};
export default AuthorDashboard;
